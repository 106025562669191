import React, { Component } from "react";
import "./styles.scss";
import RightsLogo from "../../images/Qatar-Government-Contact-Center-Logo.png";
import HomeIcon from "@mui/icons-material/Home";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import IOS from "../../images/ios_app.png";
import Android from "../../images/android_app.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

class BottomNav extends Component {
	gotoRealWesbite(link) {
		window.location.href = link;
	}

	render() {
		return (
			<div className="bottom-nav">
				<div className="bottom-nav-content">
					<div className="bottom-nav-content-left">
						<p className="bottom-subscribtion-desc">اشترك معنا بالنشرة البريدية لتحصل على آخر أخبار ومستجدات الوزارة مباشرة عبر بريدك الإلكتروني</p>
						<form
							className="bottom-subscribtion-wrapper"
							onSubmit={(e) => {
								e.preventDefault();
								e.target.reset();
							}}
						>
							<input className="bottom-subscribtion-input" type="email" placeholder="البريد الإلكتروني" />
							<button className="bottom-subscribtion-btn">اشترك</button>
						</form>
					</div>
					<div className="bottom-nav-content-right">
						<a href="/" className="bottom-nav-btn">
							<HomeIcon className="bottom-btn-icon" />
							الصفحات الرئيسية
						</a>
						<div className="bottom-navigations">
							<a href="https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/" className="bottom-nav-btn">
								عن الوزارة
							</a>
							<a href="/" className="bottom-nav-btn">
								خدماتنا
							</a>
							<a href="https://www.moci.gov.qa/%d8%a7%d9%84%d8%ae%d8%af%d9%85%d8%a7%d8%aa-%d8%a7%d9%84%d8%a7%d9%84%d9%83%d8%aa%d8%b1%d9%88%d9%86%d9%8a%d8%a9/" className="bottom-nav-btn">
								الخدمات الالكترونية
							</a>
							<a href="https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d8%b1%d9%83%d8%b2-%d8%a7%d9%84%d8%a5%d8%b9%d9%84%d8%a7%d9%85%d9%8a/" className="bottom-nav-btn">
								المركز الإعلامي
							</a>
							<a href="https://sw.gov.qa/" className="bottom-nav-btn">
								النافذة الواحدة
							</a>
							<a href="https://idb.moci.gov.qa/meidms/" className="bottom-nav-btn">
								بوابة قطر الصناعية
							</a>
							<a href="https://www.moci.gov.qa/%d8%b4%d8%b1%d9%88%d8%b7-%d8%a7%d9%84%d8%a7%d8%b3%d8%aa%d8%ae%d8%af%d8%a7%d9%85/" className="bottom-nav-btn">
								شروط الاستخدام
							</a>
							<a href="https://businessmap.moci.gov.qa/ar" className="bottom-nav-btn">
								بوابة خارطة أعمال دولة قطر
							</a>
							<a href="https://www.moci.gov.qa/%d9%85%d9%88%d8%a7%d9%82%d8%b9-%d8%aa%d9%87%d9%85%d9%83/" className="bottom-nav-btn">
								مواقع تهمك
							</a>
							<a href="https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d9%88%d8%a7%d8%b9%d9%8a%d8%af/" className="bottom-nav-btn">
								احجز موعد
							</a>
						</div>
						<div className="bottom-navigations-tel">
							<PhoneEnabledIcon className="bottom-btn-icon" />
							<p className="tel-service">مركز الاتصال 16001</p>
							<a href="https://www.facebook.com/MOCIQatar">
								<FacebookIcon className="bottom-btn-icon" />
							</a>
							<a href="https://www.twitter.com/MOCIQatar">
								<TwitterIcon className="bottom-btn-icon" />
							</a>
							<a href="https://www.instagram.com/MOCIQatar">
								<InstagramIcon className="bottom-btn-icon" />
							</a>
							<a href="https://www.youtube.com/c/MOCIQatar">
								<YouTubeIcon className="bottom-btn-icon" />
							</a>
							<a href="https://www.linkedin.com/company/MOCIQatar">
								<LinkedInIcon className="bottom-btn-icon" />
							</a>
							<a href="https://api.whatsapp.com/send?phone=97466111400" style={{ display: "flex", alignItems: "center", gap: 10, direction: "ltr", textDecoration: "none" }}>
								<span className="bottom-btn-icon" style={{ textDecoration: "none", fontSize: 14 }}>
									+974 6611 1400
								</span>
								<WhatsAppIcon className="bottom-btn-icon" />
							</a>
						</div>
						<div className="bottom-navigations-tel">
							<p className="tel-service">التواصل الإجتماعي</p>
							<div className="download-app">
								<p className="download-app-title">لتحميل تطبيق الوزارة</p>
								<a href="https://play.google.com/store/apps/details?id=com.mbt.mbt">
									<img src={Android} alt="" />
								</a>
								<a href="https://itunes.apple.com/us/app/mec-qatar/id735953928">
									<img src={IOS} alt="" />
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="website-rights">
					<div className="rights-value">وزارة التجارة والصناعة. جميع الحقوق محفوظة © {new Date().getFullYear()}</div>
					<span className="rights-value"></span>
					<img className="rights-image" src={RightsLogo} alt="" />
				</div>
			</div>
		);
	}
}

export default BottomNav;
