import React, { Component } from "react";
import "./styles.scss";
import configData from "../../includes/config.json";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";

const breadcrumbs = [
	<Link underline="hover" color="#8d1737" href="/">
		الرئيسية
	</Link>,
	<Link underline="hover" color="#8d1737" href="/khedma">
		الخدمة
	</Link>,
	<Link underline="hover" color="#8d1737" href="/register_one">
		تسجيل البيانات الشخصية
	</Link>,
	<Typography key="3">تسجيل الطلب</Typography>,
];

class RegisterTwo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			errors: {},
		};
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	async onFormSubmit(e) {
		let { errors } = this.state;
		e.preventDefault();
		const order_num = e.target.order_num.value;
		const complained = e.target.complained.value;
		const amount = e.target.amount.value;
		const id_num = this.props.match.params.id_num;
		errors = {};
		if (Object.keys(errors).length > 0) {
			this.setState({ errors });
			return;
		}
		e.target.submitBtn.disabled = true;
		this.setState({
			loading: true,
		});
		const request = {
			action: 1,
			order_num: order_num,
			complained: complained,
			amount: amount,
			id_num: id_num,
			owner: configData.owner,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		await axios({
			method: "POST",
			url: configData.server_URI + "/qatar/update-customer-details-by-id",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({
			loading: false,
		});
		e.target.submitBtn.disabled = false;
		e.target.reset();
		this.props.history.push("/upload_files/" + id_num);
	}

	render() {
		const { loading } = this.state;
		return (
			<div className="page-content register-two">
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ transform: "rotate(180deg)" }} />} className="breadcrumbs-style" aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
				<form className="form-wrapper" onSubmit={this.onFormSubmit}>
					<div className="input-wrapper">
						<label className="input-label">رقم الطلب:</label>
						<input className="form-input" name="order_num" inputMode="numeric" type="number" autoComplete="off" required />
					</div>
					<div className="input-wrapper">
						<label className="input-label">الجهة المشتكى عليها:</label>
						<input className="form-input" name="complained" autoComplete="off" required />
					</div>
					<div className="input-wrapper">
						<label className="input-label">المبلغ:</label>
						<input className="form-input" name="amount" inputMode="numeric" type="number" autoComplete="off" required />
					</div>
					<div className="form-buttons">
						<button className="form-button" name="submitBtn">
							{loading && <CircularProgress style={{ marginRight: 15, width: 25, height: 25, color: "#fff" }} />}
							التالي
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default RegisterTwo;
