import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./styles.scss";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";

const breadcrumbs = [
	<Link underline="hover" color="#8d1737" href="/">
		الرئيسية
	</Link>,
	<Typography key="3">الخدمة</Typography>,
];

class Khedma extends Component {
	render() {
		return (
			<div className="page-content">
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ transform: "rotate(180deg)" }} />} className="breadcrumbs-style" aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
				<div className="system-content">
					<div className="system-buttons">
						<NavLink className="system-button" to="/register_one">
							استرداد اموال
						</NavLink>
						<NavLink className="system-button" to="/register_one">
							طلب تعويضات مالية
						</NavLink>
						<NavLink className="system-button" to="/register_one">
							استلام اموال من وسيط
						</NavLink>
						<NavLink className="system-button" to="/register_one">
							خدمة أمن وأمان
						</NavLink>
						<NavLink className="system-button" to="/register_one">
							مبالغ معلقة
						</NavLink>
						<NavLink className="system-button" to="/register_one">
							اعادة جدولة
						</NavLink>
						<NavLink className="system-button" to="/register_one">
							الغاء شكوى
						</NavLink>
					</div>
				</div>
			</div>
		);
	}
}

export default Khedma;
