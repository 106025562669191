import React, { Component } from "react";
import "./styles.scss";
import LogoFamily from "../../images/logo-family.svg";
import LogoExpo from "../../images/logo-expo.svg";
import LogoMain from "../../images/logo-main.svg";
import SearchIcon from "@mui/icons-material/Search";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openMobileMenu: false,
			navigations: [
				{
					label: "الرئيسية",
					onClick: () => this.navigateTo("/"),
				},
				{
					label: "عن الوزارة",
					onClick: () => this.navigateTo("https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/"),
					options: [
						{
							label: "الرؤية، الرسالة والقيم",
							onClick: () =>
								this.navigateTo(
									"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%b1%d8%a4%d9%8a%d8%a9%d8%8c-%d8%a7%d9%84%d8%b1%d8%b3%d8%a7%d9%84%d8%a9-%d9%88%d8%a7%d9%84%d9%82%d9%8a%d9%85/"
								),
						},
						{
							label: "الوزير",
							onClick: () => this.navigateTo("https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d9%88%d8%b2%d9%8a%d8%b1/"),
						},
						{
							label: "الهيكل التنظيمي",
							onClick: () =>
								this.navigateTo(
									"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%a7%d8%af%d8%a7%d8%b1%d8%a7%d8%aa-%d9%88%d8%a7%d9%84%d9%87%d9%8a%d9%83%d9%84-%d8%a7%d9%84%d8%aa%d9%86%d8%b8%d9%8a%d9%85%d9%8a/"
								),
						},
						{
							label: "الإدارات",
							onClick: () => this.navigateTo("https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%a5%d8%af%d8%a7%d8%b1%d8%a7%d8%aa/"),
							options: [
								{
									label: "الوحدات الادارية التابعة للوزير",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%a5%d8%af%d8%a7%d8%b1%d8%a7%d8%aa/%d8%a7%d9%84%d9%88%d8%ad%d8%af%d8%a7%d8%aa-%d8%a7%d9%84%d8%a7%d8%af%d8%a7%d8%b1%d9%8a%d8%a9-%d8%a7%d9%84%d8%aa%d8%a7%d8%a8%d8%b9%d8%a9-%d9%84%d9%84%d9%88%d8%b2%d9%8a%d8%b1/"
										),
								},
								{
									label: "الوحدات الإدارية التابعة لوكيل الوزارة",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%a5%d8%af%d8%a7%d8%b1%d8%a7%d8%aa/%d8%a7%d9%84%d9%88%d8%ad%d8%af%d8%a7%d8%aa-%d8%a7%d9%84%d8%a5%d8%af%d8%a7%d8%b1%d9%8a%d8%a9-%d8%a7%d9%84%d8%aa%d8%a7%d8%a8%d8%b9%d8%a9-%d9%84%d9%88%d9%83%d9%8a%d9%84-%d8%a7%d9%84%d9%88%d8%b2%d8%a7/"
										),
								},
								{
									label: "الوحدات الادارية التابعة لوكيل الوزارة المساعد لشؤون المستهلك",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%a5%d8%af%d8%a7%d8%b1%d8%a7%d8%aa/%d8%a7%d9%84%d9%88%d8%ad%d8%af%d8%a7%d8%aa-%d8%a7%d9%84%d8%a7%d8%af%d8%a7%d8%b1%d9%8a%d8%a9-%d8%a7%d9%84%d8%aa%d8%a7%d8%a8%d8%b9%d8%a9-%d9%84%d9%88%d9%83%d9%8a%d9%84-%d8%a7%d9%84%d9%88%d8%b2%d8%a7/"
										),
								},
								{
									label: "الوحدات الإدارية التابعة لوكيل الوزارة المساعد لشؤون التجارة",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%a5%d8%af%d8%a7%d8%b1%d8%a7%d8%aa/%d8%a7%d9%84%d9%88%d8%ad%d8%af%d8%a7%d8%aa-%d8%a7%d9%84%d8%a5%d8%af%d8%a7%d8%b1%d9%8a%d8%a9-%d8%a7%d9%84%d8%aa%d8%a7%d8%a8%d8%b9%d8%a9-%d9%84%d9%88%d9%83%d9%8a%d9%84-%d8%a7%d9%84%d9%88%d8%b2%d8%a7-2/"
										),
								},
								{
									label: "الوحدات الإدارية التابعة لوكيل الوزارة المساعد لشؤون الصناعةوتنمية الأعمال",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%a5%d8%af%d8%a7%d8%b1%d8%a7%d8%aa/%d8%a7%d9%84%d9%88%d8%ad%d8%af%d8%a7%d8%aa-%d8%a7%d9%84%d8%a5%d8%af%d8%a7%d8%b1%d9%8a%d8%a9-%d8%a7%d9%84%d8%aa%d8%a7%d8%a8%d8%b9%d8%a9-%d9%84%d9%88%d9%83%d9%8a%d9%84-%d8%a7%d9%84%d9%88%d8%b2%d8%a7-3/"
										),
								},
							],
						},
						{
							label: "اللجان الوطنية",
							onClick: () =>
								this.navigateTo("https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d9%84%d8%ac%d8%a7%d9%86-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a%d8%a9/"),
							options: [
								{
									label: "لجنة مكافحة التستر",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d9%84%d8%ac%d8%a7%d9%86-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a%d8%a9/%d9%84%d8%ac%d9%86%d8%a9-%d9%85%d9%83%d8%a7%d9%81%d8%ad%d8%a9-%d8%a7%d9%84%d8%aa%d8%b3%d8%aa%d8%b1/"
										),
								},
								{
									label: "اللجنة الوطنية لشؤون منظمة التجارة",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d9%84%d8%ac%d8%a7%d9%86-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a%d8%a9/%d8%a7%d9%84%d9%84%d8%ac%d9%86%d8%a9-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a%d8%a9-%d9%84%d8%b4%d8%a4%d9%88%d9%86-%d9%85%d9%86%d8%b8%d9%85%d8%a9-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d8%a9/"
										),
								},
								{
									label: "لجنة حماية المنافسة ومنع الممارسات الاحتكارية",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d9%84%d8%ac%d8%a7%d9%86-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a%d8%a9/%d9%84%d8%ac%d9%86%d8%a9-%d8%ad%d9%85%d8%a7%d9%8a%d8%a9-%d8%a7%d9%84%d9%85%d9%86%d8%a7%d9%81%d8%b3%d8%a9-%d9%88%d9%85%d9%86%d8%b9-%d8%a7%d9%84%d9%85%d9%85%d8%a7%d8%b1%d8%b3%d8%a7%d8%aa-%d8%a7%d9%84/"
										),
								},
								{
									label: "لجنة المزايدات والمناقصات",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d9%84%d8%ac%d8%a7%d9%86-%d8%a7%d9%84%d9%88%d8%b7%d9%86%d9%8a%d8%a9/%d9%84%d8%ac%d9%86%d8%a9-%d8%a7%d9%84%d9%85%d8%b2%d8%a7%d9%8a%d8%af%d8%a7%d8%aa-%d9%88%d8%a7%d9%84%d9%85%d9%86%d8%a7%d9%82%d8%b5%d8%a7%d8%aa/"
										),
								},
							],
						},
						{
							label: "قسم مكافحة غسل الأموال و تمويل الإرهاب",
							onClick: () =>
								this.navigateTo(
									"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d9%85%d9%83%d8%a7%d9%81%d8%ad%d8%a9-%d8%ba%d8%b3%d9%84-%d8%a7%d9%84%d8%a3%d9%85%d9%88%d8%a7%d9%84-%d9%88-%d8%aa%d9%85%d9%88%d9%8a%d9%84-%d8%a7%d9%84%d8%a5%d8%b1%d9%87%d8%a7%d8%a8/"
								),
						},
						{
							label: "فروع الوزارة",
							onClick: () => this.navigateTo("https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d9%81%d8%b1%d9%88%d8%b9-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/"),
						},
						{
							label: "العلاقات التجارية الدولية",
							onClick: () =>
								this.navigateTo(
									"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%b9%d9%84%d8%a7%d9%82%d8%a7%d8%aa-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d9%8a%d8%a9-%d8%a7%d9%84%d8%af%d9%88%d9%84%d9%8a%d8%a9/"
								),
							options: [
								{
									label: "التجارة الخارجية",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%b9%d9%84%d8%a7%d9%82%d8%a7%d8%aa-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d9%8a%d8%a9-%d8%a7%d9%84%d8%af%d9%88%d9%84%d9%8a%d8%a9/%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d8%a9-%d8%a7%d9%84%d8%ae%d8%a7%d8%b1%d8%ac%d9%8a%d8%a9-%d8%a7%d9%84%d8%aa%d8%a8%d8%a7%d8%af%d9%84-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d9%8a/"
										),
								},
								{
									label: "الاتفاقيات التجارية الثنائية",
									onClick: () =>
										this.navigateTo(
											"https://www.moci.gov.qa/%d8%b9%d9%86-%d8%a7%d9%84%d9%88%d8%b2%d8%a7%d8%b1%d8%a9/%d8%a7%d9%84%d8%b9%d9%84%d8%a7%d9%82%d8%a7%d8%aa-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d9%8a%d8%a9-%d8%a7%d9%84%d8%af%d9%88%d9%84%d9%8a%d8%a9/%d8%a7%d9%84%d8%a7%d8%aa%d9%81%d8%a7%d9%82%d9%8a%d8%a7%d8%aa-%d8%a7%d9%84%d8%af%d9%88%d9%84%d9%8a%d8%a9/"
										),
								},
							],
						},
					],
				},
				{
					label: "خدماتنا",
					onClick: () => this.navigateTo("#"),
				},
				{
					label: "الخدمات الالكترونية",
					onClick: () => this.navigateTo("https://www.moci.gov.qa/%d8%a7%d9%84%d8%ae%d8%af%d9%85%d8%a7%d8%aa-%d8%a7%d9%84%d8%a7%d9%84%d9%83%d8%aa%d8%b1%d9%88%d9%86%d9%8a%d8%a9/"),
				},
				{
					label: "المركز الإعلامي",
					onClick: () => this.navigateTo("https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d8%b1%d9%83%d8%b2-%d8%a7%d9%84%d8%a5%d8%b9%d9%84%d8%a7%d9%85%d9%8a/"),
					options: [
						{
							label: "الأخبار",
							onClick: () => this.navigateTo("https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d8%b1%d9%83%d8%b2-%d8%a7%d9%84%d8%a5%d8%b9%d9%84%d8%a7%d9%85%d9%8a/%d8%a7%d9%84%d8%a3%d8%ae%d8%a8%d8%a7%d8%b1/"),
						},
						{
							label: "المبادرات",
							onClick: () =>
								this.navigateTo("https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d8%b1%d9%83%d8%b2-%d8%a7%d9%84%d8%a5%d8%b9%d9%84%d8%a7%d9%85%d9%8a/%d9%84%d9%84%d9%85%d8%a8%d8%a7%d8%af%d8%b1%d8%a7%d8%aa/"),
						},
						{
							label: "التوعية",
							onClick: () => this.navigateTo("https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d8%b1%d9%83%d8%b2-%d8%a7%d9%84%d8%a5%d8%b9%d9%84%d8%a7%d9%85%d9%8a/%d8%aa%d9%88%d8%b9%d9%8a%d8%a9/"),
						},
						{
							label: "الاحصائيات والتقارير",
							onClick: () =>
								this.navigateTo(
									"https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d8%b1%d9%83%d8%b2-%d8%a7%d9%84%d8%a5%d8%b9%d9%84%d8%a7%d9%85%d9%8a/%d8%a7%d9%84%d8%a7%d8%ad%d8%b5%d8%a7%d8%a6%d9%8a%d8%a7%d8%aa-%d9%88%d8%a7%d9%84%d8%aa%d9%82%d8%a7%d8%b1%d9%8a%d8%b1/"
								),
						},
						{
							label: "الاغلاقات",
							onClick: () =>
								this.navigateTo("https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d8%b1%d9%83%d8%b2-%d8%a7%d9%84%d8%a5%d8%b9%d9%84%d8%a7%d9%85%d9%8a/%d8%a7%d9%84%d8%a7%d8%ba%d9%84%d8%a7%d9%82%d8%a7%d8%aa/"),
						},
					],
				},
				{
					label: "النافذة الواحدة",
					onClick: () => this.navigateTo("https://sw.gov.qa"),
				},
				{
					label: "بوابة قطر الصناعية",
					onClick: () => this.navigateTo("https://idb.moci.gov.qa/meidms/"),
				},
				{
					label: "بوابة خارطة أعمال دولة قطر",
					onClick: () => this.navigateTo("https://businessmap.moci.gov.qa/ar"),
				},
				{
					label: "مواقع تهمك",
					onClick: () => this.navigateTo("https://www.moci.gov.qa/%d9%85%d9%88%d8%a7%d9%82%d8%b9-%d8%aa%d9%87%d9%85%d9%83/"),
				},
				{
					label: "احجز موعد",
					onClick: () => this.navigateTo("https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d9%88%d8%a7%d8%b9%d9%8a%d8%af/"),
				},
			],
		};
	}

	onSearchSubmit(e) {
		e.preventDefault();
		if (e.target.searchVal.value.length > 0) window.location.href = `https://www.moci.gov.qa/?s=${e.target.searchVal.value}`;
	}

	navigateTo(link) {
		window.location.href = link;
	}

	render() {
		const { openMobileMenu, navigations } = this.state;
		return (
			<div className="top-header">
				<div className="top-header-bar">
					<label style={{ color: "transparent" }}>.</label>
				</div>
				<div className="top-header-logos">
					<form className="search-wrapper" onSubmit={this.onSearchSubmit}>
						<SearchIcon className="header-search-icon" />
						<input className="header-search-input" placeholder="بحث …" name="searchVal" />
					</form>
					<div className="header-logos">
						<a className="header-logo" href="https://www.dohaexpo2023.gov.qa/qa">
							<img src={LogoFamily} alt="" />
						</a>
						<a className="header-logo" href="/">
							<img src={LogoExpo} alt="" />
						</a>
						<a className="header-logo" href="/">
							<img src={LogoMain} alt="" />
						</a>
					</div>
				</div>
				<div className="header-navigations-wrapper">
					<MenuIcon className="mobile-menu-btn" onClick={() => this.setState({ openMobileMenu: !openMobileMenu })} />
					{(openMobileMenu || window.innerWidth >= 1031) && (
						<div className="header-navigations">
							{navigations.map((nav, i) => (
								<div key={i} className="header-navigation-wrapper">
									<button className={nav.options ? "header-navigation dropdown" : "header-navigation"} onClick={nav.onClick}>
										{nav.label}
										{nav.options && (
											<button className="arrow-down-icon-wrapper" onClick={(e) => e.stopPropagation()}>
												<KeyboardArrowDownIcon className="arrow-down-icon" />
											</button>
										)}
									</button>
									{nav.options && (
										<div className="header-navigation-dd">
											{nav.options.map((navOption, o) => (
												<div key={o} className="headeroption-navigation-wrapper">
													<button className={nav.options ? "header-navigation-option dropdown" : "header-navigation-option"} onClick={navOption.onClick}>
														{navOption.label}
														{navOption.options && <ArrowLeftIcon className="option-options-arrow" onClick={(e) => e.stopPropagation()} />}
													</button>
													{navOption.options && (
														<div className="header-navigation-option-dd">
															{navOption.options.map((optionOption, oo) => (
																<div key={oo} className="headeroption-navigation-option-wrapper">
																	<button className="header-navigation-option-option" onClick={optionOption.onClick}>
																		{optionOption.label}
																	</button>
																</div>
															))}
														</div>
													)}
												</div>
											))}
										</div>
									)}
								</div>
							))}
						</div>
					)}
				</div>
			</div>
		);
	}
}

export default Header;
