import React, { Component } from "react";
import "./styles.scss";
import TransaprentImage from "../../images/index-banner-transparant.png";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import LatestUpdates1 from "../../images/اليوم-الرياضي2024-768x446.jpg";
import LatestUpdates2 from "../../images/مبادرة-تذكير-دعم-اسعار-اللحوم-0234-768x768.jpg";
import LatestUpdates3 from "../../images/65c883865e624_005_2024_Ar.jpg";
import NationalProducts from "../../images/National-Products-1.png";
import OnlineServicesVector from "../../images/online_services_vector.png";
import Logo from "../../images/logo.png";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Media1 from "../../images/Ministry-of-Commerce-and-Industry-1.jpg";
import Media2 from "../../images/Ministry-of-Commerce-and-Industry-2.jpg";
import Media3 from "../../images/Ministry-of-Commerce-and-Industry-3.jpg";
import Document from "../../images/document.png";
import Map from "../../images/map.png";
import MapMobile from "../../images/map-mobile.png";

class Home extends Component {
	render() {
		return (
			<div className="page-content">
				<section className="home-top-section">
					<img src={TransaprentImage} style={{ maxWidth: "100%", height: "auto" }} alt="" />
					<span class="intro-text">نحـــو مـــزيد مـن الازدهـــار</span>
				</section>
				<section className="latest_updates-section">
					<div className="latest-updates">
						<div className="latest-update" style={{ borderColor: "#f8d952" }}>
							<div className="latest-update-header">
								<div class="latest-update-icon" style={{ width: 50, height: 54, backgroundPosition: "-145px 0px" }}></div>
								<div className="latest-update-header-content">
									<a
										href="https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d8%b1%d9%83%d8%b2-%d8%a7%d9%84%d8%a5%d8%b9%d9%84%d8%a7%d9%85%d9%8a/%d8%a7%d9%84%d8%a3%d8%ae%d8%a8%d8%a7%d8%b1/"
										className="latest-update-title"
									>
										آخر الأخبار
									</a>
									<a
										href="https://www.moci.gov.qa/mec_news/%d9%88%d8%b2%d8%a7%d8%b1%d8%a9-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d8%a9-%d9%88%d8%a7%d9%84%d8%b5%d9%86%d8%a7%d8%b9%d8%a9-%d8%aa%d9%86%d8%b8%d9%85-%d8%a3%d9%86%d8%b4%d8%b7%d8%a9-%d8%b1%d9%8a%d8%a7-2/"
										className="latest-update-value"
									>
										وزارة التجارة والصناعة تنظم أنشطة رياضية وترفيهية متنوعة احتفاءً باليوم الرياضي للدولة
									</a>
									<p className="latest-update-date">
										2024 فبراير 13
										<CalendarMonthIcon style={{ fontSize: 15 }} />
									</p>
								</div>
							</div>
							<a href="https://www.moci.gov.qa/mec_news/%d9%88%d8%b2%d8%a7%d8%b1%d8%a9-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d8%a9-%d9%88%d8%a7%d9%84%d8%b5%d9%86%d8%a7%d8%b9%d8%a9-%d8%aa%d9%86%d8%b8%d9%85-%d8%a3%d9%86%d8%b4%d8%b7%d8%a9-%d8%b1%d9%8a%d8%a7-2/">
								<div className="latest-update-image-wrapper" style={{ borderColor: "#f8d952" }}>
									<img className="latest-update-image" src={LatestUpdates1} alt="" />
									<div class="latest-update-readmore" style={{ backgroundColor: "#f8d952" }}>
										التفاصيل
									</div>
								</div>
							</a>
						</div>

						<div className="latest-update" style={{ borderColor: "#2d8c46" }}>
							<div className="latest-update-header">
								<div class="latest-update-icon" style={{ width: 45, height: 55, backgroundPosition: "-208px 0px" }}></div>
								<div className="latest-update-header-content">
									<a
										href="https://www.moci.gov.qa/%d8%a7%d9%84%d9%85%d8%b1%d9%83%d8%b2-%d8%a7%d9%84%d8%a5%d8%b9%d9%84%d8%a7%d9%85%d9%8a/%d9%84%d9%84%d9%85%d8%a8%d8%a7%d8%af%d8%b1%d8%a7%d8%aa/"
										className="latest-update-title"
									>
										آخر المبادرات
									</a>
									<a
										href="https://www.moci.gov.qa/mec_news/%d9%88%d8%b2%d8%a7%d8%b1%d8%a9-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d8%a9-%d9%88%d8%a7%d9%84%d8%b5%d9%86%d8%a7%d8%b9%d8%a9-%d8%aa%d8%b9%d9%84%d9%86-%d8%b9%d9%86-%d8%a8%d8%af%d8%a1-%d8%a7%d9%84%d8%aa-5/"
										className="latest-update-value"
									>
										وزارة التجارة والصناعة تعلن عن بدء التسجيل في المبادرة الوطنية لتشجيع الإنتاج المحلي ودعم أسعار لحوم الأغنام
									</a>
									<p className="latest-update-date">
										2024 يناير 08
										<CalendarMonthIcon style={{ fontSize: 15 }} />
									</p>
								</div>
							</div>
							<a href="https://www.moci.gov.qa/mec_news/%d9%88%d8%b2%d8%a7%d8%b1%d8%a9-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d8%a9-%d9%88%d8%a7%d9%84%d8%b5%d9%86%d8%a7%d8%b9%d8%a9-%d8%aa%d8%b9%d9%84%d9%86-%d8%b9%d9%86-%d8%a8%d8%af%d8%a1-%d8%a7%d9%84%d8%aa-5/">
								<div className="latest-update-image-wrapper" style={{ borderColor: "#2d8c46" }}>
									<img className="latest-update-image" src={LatestUpdates2} alt="" />
									<div class="latest-update-readmore" style={{ backgroundColor: "#2d8c46", color: "#FFF" }}>
										التفاصيل
									</div>
								</div>
							</a>
						</div>

						<div className="latest-update" style={{ borderColor: "#8b1736" }}>
							<div className="latest-update-header">
								<div class="latest-update-icon" style={{ width: 72, height: 43, backgroundPosition: "0px 0px" }}></div>
								<div className="latest-update-header-content">
									<a href="https://recall.moci.gov.qa/" className="latest-update-title">
										آخر الاستدعاءات
									</a>
									<p className="latest-update-value">نضع لكم هنا آخر الاستدعاءات للسلع والمركبات المعيبه بجميع تفاصيلها</p>
									<p className="latest-update-date">
										2024 فبراير 14
										<CalendarMonthIcon style={{ fontSize: 15 }} />
									</p>
								</div>
							</div>
							<a href="https://recall.moci.gov.qa/">
								<div className="latest-update-image-wrapper" style={{ borderColor: "#8b1736" }}>
									<img className="latest-update-image" src={LatestUpdates3} alt="" />
									<div class="latest-update-readmore" style={{ backgroundColor: "#8b1736", color: "#FFF" }}>
										التفاصيل
									</div>
								</div>
							</a>
						</div>
					</div>
				</section>
				<section className="anim_reveal-section">
					<div className="anim_reveal-content">
						<p className="anim_reveal-title">منتج قطري</p>
						<div className="anim_reveal-content-btn-wrapper">
							<p className="anim_reveal-desc">دليل المصنعين والمنتجين في قطر</p>
							<a
								href="https://www.moci.gov.qa/%d8%af%d9%84%d9%8a%d9%84-%d8%a7%d9%84%d9%85%d8%b5%d9%86%d8%b9%d9%8a%d9%86-%d9%88%d8%a7%d9%84%d9%85%d9%86%d8%aa%d8%ac%d9%8a%d9%86-%d9%81%d9%8a-%d9%82%d8%b7%d8%b1/"
								className="anim_reveal-btn"
							>
								إضغط هنا
							</a>
						</div>
						<img src={NationalProducts} alt="" className="anim_reveal-img" />
					</div>
				</section>
				<section className="bss-services-section">
					<div className="bss-services-content">
						<div className="bss-service">
							<p className="bss-service-main-title">البحث عن أسماء التجارة وأنشطة الأعمال</p>
							<p className="bss-service-main-value">البحث عن أسماء التجارة وأنشطة الأعمال هو خدمة متخصصة تساعد الأفراد والمنظمات على التحقق من توافر وحالة أسماء الأعمال المحددة والأنشطة المرتبطة بها.</p>
						</div>
						<a className="bss-service" href="https://www.moci.gov.qa/%d8%a7%d9%84%d8%a3%d8%b3%d9%85%d8%a7%d8%a1-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d9%8a%d8%a9/">
							<div className="bss-service">
								<div className="bss-service-img trade" />
								<p className="bss-service-title">الأسماء التجارية</p>
								<p className="bss-service-value">باستخدام هذه الخدمة، يمكنك البحث عن، وإدراج، والحصول على معلومات حول أسماء التجارة المتعددة.</p>
							</div>
						</a>
						<a className="bss-service" href="https://www.moci.gov.qa/%d8%a7%d9%84%d8%a3%d9%86%d8%b4%d8%b7%d8%a9-%d8%a7%d9%84%d8%aa%d8%ac%d8%a7%d8%b1%d9%8a%d8%a9/">
							<div className="bss-service">
								<div className="bss-service-img business" />
								<p className="bss-service-title">الأنشطة التجارية</p>
								<p className="bss-service-value">باستخدام هذه الخدمة، يمكنك البحث عن، وإدراج، والحصول على معلومات حول أنشطة الأعمال المتعددة.</p>
							</div>
						</a>
					</div>
				</section>
				<section className="bss-services-section" style={{ backgroundColor: "#f7f8f9" }}>
					<div className="bss-services-content">
						<div className="bss-service">
							<p className="bss-service-main-title">الخدمات ونماذج المعاملات</p>
							<p className="bss-service-main-value">تقدم وزارة التجارة والصناعة مجموعة متكاملة من الخدمات، والنماذج، والمعلومات الهامة والمفيدة للمستثمرين والمستهلكين</p>
						</div>
						<a className="bss-service link" href="https://www.moci.gov.qa/%d8%ae%d8%af%d9%85%d8%a7%d8%aa%d9%86%d8%a7/%d8%a7%d9%84%d9%85%d8%b3%d8%aa%d8%ab%d9%85%d8%b1/">
							<div className="bss-service">
								<div className="bss-service-img ">
									<div className="bss-service-img tejara" />
								</div>
								<p className="bss-service-title">التجارة</p>
								<p className="bss-service-value">اطّلع على النماذج والخدمات والمعلومات الهامة المقدمة للمستثمر المحلي والأجنبي</p>
							</div>
						</a>
						<a className="bss-service" href="https://www.moci.gov.qa/%d8%ae%d8%af%d9%85%d8%a7%d8%aa%d9%86%d8%a7/%d8%a7%d9%84%d9%85%d8%b3%d8%aa%d9%87%d9%84%d9%83/">
							<div className="bss-service">
								<div className="bss-service-img ">
									<div className="bss-service-img consumer" />
								</div>
								<p className="bss-service-title">المستهلك</p>
								<p className="bss-service-value">اطّلع على النماذج والخدمات والمعلومات الهامة المتوفرة للمستهلك</p>
							</div>
						</a>
					</div>
				</section>
				<section className="thousand-banner-section">
					<div className="thousand-banner-content">
						<div className="thousand-banner-right">
							<img className="thousand-banner-img" src={Logo} alt="" />
						</div>
						<div className="thousand-banner-left">
							<p className="thousand-banner-desc">
								تتيح هذه الخدمة للمستثمرين المحليين الحصول عــلى الفــرص الاستثمارية التي تقدمـــهـا كبــــــرى الشـــــركــــــات الأجــنــــبيـــة والمـحــلية الـــعامــلة فـــي دولـــــة قــطــر.
							</p>
							<a href="https://www.moci.gov.qa/%D8%A3%D9%84%D9%81-%D9%81%D8%B1%D8%B5%D8%A9/" className="thousand-banner-btn">
								لمعرفة المزيد <KeyboardBackspaceIcon />
							</a>
						</div>
					</div>
				</section>
				<section className="online_services-section">
					<div className="online_services-content">
						<div className="online_service">
							<p className="online_service-main-title">خدماتنا الإلكترونية</p>
							<p className="online_service-main-value">تقدم الوزارة مجموعة من الخدمات الإلكترونية المتنوعة</p>
							<a href="/Khedma" className="online_service-btn">
								الدخول
							</a>
						</div>
						<img src={OnlineServicesVector} alt="" />
					</div>
				</section>
				<section className="facts_and_figures-section">
					<div className="facts_and_figures-content">
						<div className="facts_and_figures">
							<p className="facts_and_figures-main-title">حقائق وأرقام</p>
							<p className="facts_and_figures-main-value">تمكنت دولة قطر من ترسيخ مكانتها على خارطة الاقتصاد العالمي حيث صنفت الدولة خلال السنوات الماضية كواحدة من أسرع الاقتصادات الناشئة في العالم.</p>
						</div>
						<div className="facts_and_figures-scroll-container">
							<ArrowForwardIosIcon className="arrow-icon" onClick={() => console.log("asd")} />
							<div className="facts_and_figures-scroll-child">
								<div className="child-num-wrapper">
									<span className="child-num">1</span>
									<span className="child-snum">st</span>
								</div>
								<p className="child-mid-title">عالمياً</p>
								<p className="child-maroon">مؤشر الأمان</p>
								<p className="child-desc">المصدر: تـصـنـيـف مـجـلـة جلوبال فـاينانس لمقاييس السلامة والأمان العالمي لعام 2019</p>
							</div>
							<ArrowBackIosNewIcon className="arrow-icon" />
						</div>
					</div>
				</section>
				<section className="media_center-section">
					<div className="media_center-content">
						<div className="media_center">
							<p className="media_center-main-title">المركز الاعلامي</p>
						</div>
						<div className="media_center-images">
							<img className="document-icon" src={Document} alt="" />
							<div className="media_center-image-wrapper">
								<img className="media_center-image" src={Media1} alt="" />
							</div>
							<div className="media_center-image-wrapper">
								<img className="media_center-image" src={Media2} alt="" />
							</div>
							<div className="media_center-image-wrapper">
								<img className="media_center-image" src={Media3} alt="" />
							</div>
						</div>
					</div>
				</section>
				<section className="our-loaction-section">
					<img className="our-location" src={Map} alt="" />
					<img className="our-location-mobile" src={MapMobile} alt="" />
				</section>
			</div>
		);
	}
}

export default Home;
